<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <p class="header-title">아이디 ∙ 비밀번호 찾기</p>
      </header>
      <div class="contentBox" v-if="!isSuc">
        <p style="font-size: 12px">비밀번호 변경</p>
        <input
          type="password"
          class="input-full-width mr-8"
          style="margin-top: 8px"
          placeholder="영어, 숫자, 특수문자를 포함하여 8~16자 이내로 작성해 주세요"
          v-model="pw"
        />
        <div class="error mt-16" v-if="!checkValid">
          영어, 숫자, 특수문자를 포함해서 8~16자 이내로 입력해주세요
        </div>
        <p style="font-size: 12px; margin-top: 32px">비밀번호 변경</p>
        <input
          type="password"
          class="input-full-width mr-8"
          style="margin-top: 8px"
          placeholder="비밀번호를 한번 더 기재해 주세요"
          v-model="pwCheck"
        />
        <div class="error mt-16" v-if="pw !== pwCheck">
          비밀번호가 일치하지 않습니다.
        </div>
      </div>
      <!-- 성공했을경우 -->
      <div class="contentBox" v-if="isSuc">
        <p class="suc-title">비밀번호 변경 완료</p>
        <p class="suc-content" style="margin: 50px 0">
          변경된 비밀번호로 로그인을 진행해 주세요.
        </p>
      </div>
    </div>
    <div class="next mb-80" style="margin-top: 62px" v-if="!isSuc">
      <button-common
        :size="SIZE_S"
        :clr="CLR_W"
        style="margin-right: 16px"
        @click="goBack()"
        >이전</button-common
      >
      <button-common
        :disabled="!checkValid || pw !== pwCheck"
        :size="SIZE_S"
        :clr="CLR_B"
        @click="resetPW()"
        >변경완료</button-common
      >
    </div>
    <div class="next mb-80" style="margin-top: 62px" v-if="isSuc">
      <button-common :size="SIZE_S" :clr="CLR_B" @click="goLoginPage()"
        >로그인</button-common
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "FindPW",
  components: {},
  data() {
    return {
      pw: "",
      pwCheck: "",
      isSuc: false,
    };
  },
  computed: {
    checkValid() {
      const regex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
      return regex.test(this.pw);
    },
  },
  methods: {
    async resetPW() {
      const rs = await this.$axios({
        url: "/partner/find/pw/done",
        params: { pass: this.pw, rePass: this.pw },
      });
      if (rs.result === "SUC") {
        this.isSuc = true;
      }
    },
    goLoginPage() {
      this.$router.push({
        path: `/login`,
        meta: { id: "0-1-1" },
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";
.header-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 63px;
}
.li-wrap {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 12px;
}
.error {
  color: #f1431d;
  font-size: 14px;
}
.confirm {
  color: #2ec8b5;
  font-size: 14px;
}
.address-title {
  min-width: 200px;
  padding-left: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #3a3d4d;
}
.address-value {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #696c80;
}
.business-info {
  font-weight: 500;
  font-size: 12px;
  color: #828699;
}
.div-circle {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #dddddd;
  margin: 0 2px;
}
.explanation-title {
  font-size: 12px;
  color: #888888;
  margin-right: 50px;
}
.explanation-sub-title {
  font-size: 12px;
  color: #888888;
  margin-right: 50px;
  text-decoration: underline;
  cursor: pointer;
}
.footer_ch {
  width: 16px;
  height: 17px;
  margin-right: 2px;
}
.suc-title {
  color: #3a3d4d !important;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 25px;
}
.suc-email {
  color: #2ccdc3 !important;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-top: 25px;
}
.suc-content {
  color: #888888 !important;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
</style>
